import moment from 'moment'

export default {
  filters: {
    dateToWords: function (value) {
      return value ? moment(value).format('MMMM YYYY') : ''
    },
    localDate: function (value) {
      return value ? moment(value).format('MMMM DD, YYYY') : ''
    }
  }
}
