<template>
  <v-row justify="center" :class="$vuetify.breakpoint.mdAndUp ? 'py-5' : ''">
    <v-col v-if="!isFetching" cols="12" sm="11" md="9" lg="6" xl="5">
      <v-container v-if="error" fill-height text-xs-center style="margin-top: 64px">
        <v-layout row wrap align-center justify-center>
          <v-flex xs8>
            <v-sheet class="dropShadow pa-4">
              <p><v-icon large class="error--text">error_outline</v-icon></p>
              <span class="title">{{ error }}</span>
            </v-sheet>
          </v-flex>
        </v-layout>
      </v-container>
      <div v-else>
        <v-card class="pa-3">
          <v-row>
            <v-col cols="2" sm="2" class="pr-1">
              <v-row align="center" justify="center" no-gutters>

                <v-col cols="12">
                  <v-img
                    :src="lawyer.imageUrl || 'https://cdn0.iconfinder.com/data/icons/user-pictures/100/unknown2-512.png'"
                    style="border-radius: 8%"
                    aspect-ratio="1"
                    alt="avatar"
                  ></v-img>
                </v-col>

                <v-col cols="12" class="text-center pt-1">
                  <v-chip color="primary" small label>
                    <v-icon small>mdi-star</v-icon>
                    <span class="pl-1">{{ reviews | calculateRating }}</span>
                  </v-chip>
                </v-col>

                <v-col cols="12" class="text-center pt-1">
                  <span class="grey--text body-2">({{ reviews.length }} reviews)</span>
                </v-col>
              </v-row>
              
            </v-col>

            <v-col cols="4" :class="{'pl-1': $vuetify.breakpoint.mdAndUp, 'pl-1': $vuetify.breakpoint.smAndDown }" >
              <v-row align="center" justify="center" no-gutters>

                <!-- <v-col cols="12">
                  <v-row align="center" no-gutters>
                    <v-rating
                      v-if="reviews"
                      readonly
                      small
                      dense
                      class="pr-1"
                      color="primary"
                      background-color="grey"
                      empty-icon="star"
                      :value="reviews | calculateRating"
                    ></v-rating>
                    <span class="grey--text body-2 pt-1">
                      {{ reviews | calculateRating }} ({{ reviews.length }} reviews)
                    </span>
                  </v-row>
                </v-col> -->

                <v-col cols="12">
                  <span class="text-xs-h6 font-weight-medium">{{ lawyer.fullName }}</span>
                </v-col>

                <v-col cols="12">
                  <span class="caption font-weight-medium primary--text">{{ lawyer.city + ', ' + lawyer.province }}</span>
                </v-col>
                
                <v-col cols="4" class="pa-0">
                  <span class="caption grey--text">Roll Number</span>
                </v-col>

                <v-col cols="8" class="pa-0">
                  <span class="caption primary--text">{{ lawyer.rollNumber }}</span>
                </v-col>

                <v-col cols="5" class="pa-0">
                  <span class="caption grey--text">Year Admitted</span>
                </v-col>

                <v-col cols="7" class="pa-0">
                  <span class="caption primary--text">{{ lawyer.yearAdmitted }}</span>
                </v-col>

                <v-col cols="12">
                  <span v-if="lawyer.lawOffice" class="subheading font-weight-medium semi-black body-2">
                    <v-icon small class="pr-2">fa-balance-scale</v-icon>{{ lawyer.lawOffice }}
                  </span>
                </v-col>
                
                <v-col cols="12" class="pa-0">
                  <v-chip
                    v-for="specs in lawyer.specialization" :key="specs.index"
                    color="primary"
                    label
                    dark
                    small
                    outlined
                    class="mr-1 mt-1"
                  >{{ specs }}</v-chip>
                </v-col>
              </v-row>
              
            </v-col>

            <v-divider v-if="lawyer.education" class="pl-3 mb-4" vertical inset></v-divider>

            <v-col v-if="lawyer.education" cols="5" class="pl-10">

              <v-col cols="12" class="pa-0">
                <span class="body-2 font-weight-medium">Educational Background</span>
              </v-col>
              
              <v-row v-for="(educ, index) in lawyer.education" :key="index" justify="center" align="center" no-gutters>

                <!-- Pre-Law Area -->
                <v-row v-if="educ.level === 'Pre-Law'" align="center" justify="center" no-gutters>
                  <v-col cols="12">
                    <span class="caption font-weight-medium">Pre-Law</span>
                  </v-col>

                  <v-col cols="12">
                    <v-row no-gutters>
                      <v-col cols="1">
                        <v-icon small>mdi-school</v-icon>
                      </v-col>

                      <v-col cols="11">
                        <span class="caption font-weight-medium primary--text">{{ educ.degree }}</span>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12">
                    <v-row no-gutters>
                      <v-col cols="1">
                        <v-icon small>mdi-home-city</v-icon>
                      </v-col>

                      <v-col cols="11">
                        <span class="caption font-weight-medium primary--text">{{ educ.school }}</span>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <!-- Pre-Law Area -->

                <!-- Law Area -->
                <v-row v-if="educ.level === 'Law'" align="center" justify="center" no-gutters>
                  <v-col cols="12">
                    <span class="caption font-weight-medium">Law</span>
                  </v-col>

                  <v-col cols="12">
                    <v-row no-gutters>
                      <v-col cols="1">
                        <v-icon small>mdi-school</v-icon>
                      </v-col>

                      <v-col cols="11">
                        <span class="caption font-weight-medium primary--text">{{ educ.degree }}</span>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12">
                    <v-row no-gutters>
                      <v-col cols="1">
                        <v-icon small>mdi-home-city</v-icon>
                      </v-col>

                      <v-col cols="11">
                        <span class="caption font-weight-medium primary--text">{{ educ.school }}</span>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <!-- Law Area -->

                <!-- Masters Area -->
                <v-row v-if="educ.level === 'Masters'" align="center" justify="center" no-gutters>
                  <v-col cols="12">
                    <span class="caption font-weight-medium">Masters</span>
                  </v-col>

                  <v-col cols="12">
                    <v-row no-gutters>
                      <v-col cols="1">
                        <v-icon small>mdi-school</v-icon>
                      </v-col>

                      <v-col cols="11">
                        <span class="caption font-weight-medium primary--text">{{ educ.degree }}</span>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12">
                    <v-row no-gutters>
                      <v-col cols="1">
                        <v-icon small>mdi-home-city</v-icon>
                      </v-col>

                      <v-col cols="11">
                        <span class="caption font-weight-medium primary--text">{{ educ.school }}</span>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <!-- Masters Area -->

              </v-row>
            </v-col>

            <v-col cols="12" v-if="lawyer.description">
              <p class="title grey--text mb-0">Overview</p>
              <p class="my-2" v-for="line in lawyer.description.split('\n')" :key="line.key">{{ line }}</p>
            </v-col>
            <!-- This is the contacts area of the lawyer -->
            <!-- <v-flex xs12 px-3 mt-2>
              <p class="title grey--text mb-0">Contacts</p>
              <v-list class="py-0 ">
                <div>
                  <v-list-tile>
                    <v-list-tile-action>
                      <v-icon color="primary">mail</v-icon>
                    </v-list-tile-action>
                    <v-list-tile-content>
                      <v-list-tile-title>{{ lawyer.email }}</v-list-tile-title>
                    </v-list-tile-content>
                  </v-list-tile>
                </div>
                <div v-if="lawyer.contacts">
                  <v-list-tile>
                    <v-list-tile-action>
                      <v-icon color="primary">phone</v-icon>
                    </v-list-tile-action>
                    <v-list-tile-content v-for="number in lawyer.contacts" :key="number.key">
                      <v-list-tile-title>{{ number }}</v-list-tile-title>
                    </v-list-tile-content>
                  </v-list-tile>
                </div>
                <div v-if="lawyer.completeAddress">
                  <v-list-tile>
                    <v-list-tile-action>
                      <v-icon color="primary">location_on</v-icon>
                    </v-list-tile-action>
                    <v-list-tile-content>
                      <v-list-tile-title>{{ lawyer.completeAddress }}</v-list-tile-title>
                    </v-list-tile-content>
                  </v-list-tile>
                </div>
              </v-list>
            </v-flex> -->
          </v-row>
        </v-card>
        <v-card v-if="reviews.length > 0" class="px-3 mt-2 mb-4">
          <v-row>
            <v-col cols="12">
              <p class="text-md-h6 font-weight-medium grey--text ma-0">Reviews</p>
              <v-row v-for="review in reviews" :key="review.index">
                <v-col cols="12" class="py-3">
                  <v-divider></v-divider>
                  <p class="font-weight-bold primary--text pt-3">{{ review.problemTitle }}</p>
                  <v-card-actions class="pl-0" style="margin-top: -20px;">
                    <v-rating
                      readonly
                      dense
                      small
                      color="primary"
                      background-color="grey"
                      empty-icon="star"
                      :value="review.rating"
                    ></v-rating>
                    <span class="font-weight-bold mx-2">
                      {{ review.rating }} Stars
                    </span>
                    <span class="grey--text mx-2 body-2">
                      {{ review.createdAt | localDate }}
                    </span>
                  </v-card-actions>
                  <v-flex xs12>
                    <p v-for="line in review.comment.split('\n')" :key="line.key">{{ line }}</p>
                  </v-flex>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </div>
    </v-col>
    <div v-else class="text-xs-center" style="margin-top: 16rem;">
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
  </v-row>
</template>

<script>
import UserService from '@/services/UserService'
import dateToWords from '@/mixins/momentDateToWordsMixin'
import calculateRating from '@/mixins/calculateRatingMixin'

export default {
  mixins: [
    dateToWords,
    calculateRating
  ],
  data () {
    return {
      isFetching: false,
      error: undefined,
      lawyer: {},
      reviews: {}
    }
  },
  methods: {
    getLawyer () {
      this.isFetching = true
      UserService.getLawyer({
        lawyerId: this.$route.params.lawyerId
      })
        .then(res => {
          this.lawyer = res.data.lawyer
          this.reviews = res.data.reviews
        })
        .catch((error) => {
          if (error.response.status === 404) {
            this.error = error.response.data
          } else {
            this.error = 'Opps! Something went wrong on our end.'
          }
        })
        .finally(() => {
          this.isFetching = false
        })
    }
  },
  mounted () {
    this.getLawyer()
  }
}
</script>

<style scoped>
  .forceAlign {
    align-items: center
  }
</style>
